<template>
  <ul class="nav nav-pills">
    <li class="nav-item">
      <a class="nav-link" :class="{active: selected === 'all_time'}" href="#" @click.prevent="valueChanged('all_time')">All<span> Time</span></a>
    </li>
    <li class="nav-item">
      <a class="nav-link" :class="{active: selected === 'last_year'}" href="#" @click.prevent="valueChanged('last_year')"><span>Last </span>Year</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" :class="{active: selected === 'last_month'}" href="#" @click.prevent="valueChanged('last_month')"><span>Last </span>Month</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" :class="{active: selected === 'last_week'}" href="#" @click.prevent="valueChanged('last_week')"><span>Last </span>Week</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" :class="{active: selected === 'custom'}" href="#" @click.prevent="selectCustomDates()">
        <template v-if="selected !== 'custom'">Custom</template>
        <template v-else>{{sinceDisplay}} &middot; {{untilDisplay}}</template>
      </a>
    </li>
    <portal to="modals" id="chooseDateRangeModal" v-if="showModal" no-close-on-backdrop>
      <b-modal v-model="showModal" body-bg-variant="default no-scrollbars" title="Choose date range">
        <v-date-picker
          mode='range'
          :locale="{id: 'en', masks: { input: 'YYYY-MM-DD', data: 'YYYY-MM-DD'}}"
          v-model='dateRange'
        />
        <small class="text-danger" v-if="showWarn">You must choose two dates to specify the dates range to filter out.</small>
        <template v-slot:modal-footer>
          <b-button variant="primary" @click="confirmDates()">
            OK
          </b-button>
          <b-button variant="secondary" @click="cancelDates()">
            Cancel
          </b-button>
        </template>

      </b-modal>
    </portal>
  </ul>
</template>

<script>
  import { BModal, BButton } from 'bootstrap-vue'
  import moment from 'moment'
  import { mapMutations, mapGetters } from 'vuex'

  export default {
    components: {
      BModal,
      BButton
    },
    computed: {
      ...mapGetters(['getPeriod', 'getSince', 'getUntil']),
      sinceDisplay() {
        if (this.appliedRange && this.appliedRange.start) {
          return moment(this.appliedRange.start).format("YYYY-M-D")
        }
        return ''
      },
      untilDisplay() {
        if (this.appliedRange && this.appliedRange.end) {
          return moment(this.appliedRange.end).format("YYYY-M-D")
        }
        return ''
      }
    },
    methods: {
      ...mapMutations(['setDateFilter']),
      selectCustomDates () {
        this.dateRange = this.appliedRange
        this.showModal = true
        this.prevSelected = this.selected
        this.selected = 'custom'
        this.showWarn = false
      },
      confirmDates () {
        if (this.dateRange === null || this.dateRange.start === null || this.dateRange.end === null) {
          this.showWarn = true
          return
        }
        this.appliedRange = this.dateRange
        this.showModal = false
        this.valueChanged('custom')
      },
      cancelDates () {
        this.showModal = false
        this.valueChanged(this.prevSelected)
      },
      valueChanged (newValue) {
        this.selected = newValue
        if (newValue !== 'custom') {
          this.appliedRange.start = null
          this.appliedRange.end = null          
        }
        let payload = { period: this.selected, since: this.sinceDisplay, until: this.untilDisplay }
        this.$emit('change', payload)
        this.setDateFilter(payload)
      }
    },
    mounted () {
      this.selected = this.getPeriod
      this.appliedRange.start = this.getSince
      this.appliedRange.end = this.getUntil
    },
    data () {
      return {
        selected: 'last_week',
        showModal: false,
        prevSelected: null,
        showWarn: false,
        appliedRange: {
          start: null,
          end: null
        },
        dateRange: {
          start: null,
          end: null
        }
      }
    }
  };
</script>

<style>
.nav.nav-pills {
  margin-right: 23px;
}
.modal .modal-body.no-scrollbars {
  overflow-y: unset;
}

@media screen and (max-width: 767px) {
  .nav.nav-pills .nav-item .nav-link span {
    display: none;
  }
  .nav.nav-pills .nav-item .nav-link strong {
    font-size: 0.9em
  }
}

</style>
