import StatsAPI from '@/api/api'


export default {
  state: {
    tours: [],
    tours_count: null,
    tour_medias: [],
    tour_hotspots: [],
    tour_chart: {},
    sortConfig: {
      sortKey: 'percentage',
      sortOrder: 'desc'
    }
  },
  mutations: {
    setTours: (state, payload) => {
      state.tours = payload
      state.tours_count = state.tours.length
    },
    setTour: (state, payload) => {
      let position = state.tours.findIndex(item => item.code === payload.code)
      let tours = state.tours
      tours[position].uuid = payload.uuid
      state.tours = tours
      // this._vm.$set(state.tours, position, payload)
    },
    sortTours: (state, { sortKey, sortOrder }) => {
      let compareValues = (key, order = 'asc') => {
        let k = key
        if (key === 'percentage') {
          k = 'visits'
        }
        return function innerSort(a, b) {
          let varA
          let varB
          if (k === 'country') {
            varA = a['top_country']['views']
            varB = b['top_country']['views']
          } else if (k === 'percentage') {
            varA = parseFloat(a['percentage'].substring(0, a['percentage'].length - 1))
            varB = parseFloat(b['percentage'].substring(0, b['percentage'].length - 1))
          } else {
            varA = (typeof a[k] === 'string')
              ? a[k].toUpperCase() : a[k];
            varB = (typeof b[k] === 'string')
              ? b[k].toUpperCase() : b[k];
          } 

          let comparison = 0;
          if (varA > varB) {
            comparison = 1;
          } else if (varA < varB) {
            comparison = -1;
          }
          return (
            (order === 'desc') ? (comparison * -1) : comparison
          );
        };
      }
      const tours = state.tours
      tours.sort(compareValues(sortKey, sortOrder))
      state.tours = tours
    },
    setTourMedias: (state, payload) => {
      let compareValues = (order = 'desc') => {
        return function innerSort(a, b) {
          let varA = a['visits']
          let varB = b['visits']

          let comparison = 0;
          if (varA > varB) {
            comparison = 1;
          } else if (varA < varB) {
            comparison = -1;
          }
          return (
            (order === 'desc') ? (comparison * -1) : comparison
          );
        };
      }
      const medias = payload
      medias.sort(compareValues('desc'))
      state.tour_medias = medias
    },
    setTourHotspots: (state, payload) => {
      let compareValues = (order = 'desc') => {
        return function innerSort(a, b) {
          let varA = a['clicks']
          let varB = b['clicks']

          let comparison = 0;
          if (varA > varB) {
            comparison = 1;
          } else if (varA < varB) {
            comparison = -1;
          }
          return (
            (order === 'desc') ? (comparison * -1) : comparison
          );
        };
      }
      const hotspots = payload
      hotspots.sort(compareValues('desc'))
      state.tour_hotspots = payload
    },
    setTourChart: (state, payload) => {
      state.tour_chart = payload
    },
    setSortConfig: (state, payload) => {
      state.sortConfig = payload
    }
  },
  getters: {
    getTours (state) {
      return state.tours
    },
    getTourMedias (state) {
      return state.tour_medias
    },
    getTourHotspots (state) {
      return state.tour_hotspots
    },
    getToursCount (state) {
      return state.tours_count
    },
    getTourChart (state) {
      return state.tour_chart
    },
    getSortConfig (state) {
      return state.sortConfig
    }
  },
  actions: {
    updateTours (context, payload) {
      return StatsAPI.analytics_by_tour(payload).then((response) => {
        context.commit('setTours', response.data)
      })
    },
    regenerateTourUuid (context, payload) {
      return StatsAPI.regenerate_tour_uuid(payload).then((response) => {
        context.commit('setTour', response.data)
        return response.data
      })
    },
    updateTourMedias (context, payload) {
      return StatsAPI.tour_medias(payload).then((response) => {
        context.commit('setTourMedias', response.data)
      })
    },
    updateTourHotspots (context, payload) {
      return StatsAPI.tour_hotspots(payload).then((response) => {
        context.commit('setTourHotspots', response.data)
      })
    },
    updateTourDailyChart(context, payload) {
      return StatsAPI.daily_tour_chart(payload).then((response) => {
        context.commit('setTourChart', response.data)
      })
    }
  }
}
