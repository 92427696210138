export default {
  state: {
    period: 'last_week',
    since: null,
    until: null 
  },
  mutations: {
    setDateFilter: (state, payload) => {
      state.period = payload.period
      state.since = payload.since
      state.until = payload.until
    }
  },
  getters: {
    getPeriod (state) {
      return state.period
    },
    getSince (state) {
      return state.since
    },
    getUntil (state) {
      return state.until
    },
    getDateFilterPayload (state) {
      return {
        'period': state.period,
        'since': state.since,
        'until': state.until
      }
    }
  }
}
