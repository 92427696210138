import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import PortalVue from 'portal-vue'
import VCalendar from 'v-calendar'
import VueSweetalert2 from 'vue-sweetalert2'

// Styling
import '@/assets/scss/fonts.scss'
import '../node_modules/bootstrap/scss/bootstrap.scss'
import "@fortawesome/fontawesome-free/scss/fontawesome.scss"
import "@fortawesome/fontawesome-free/scss/solid.scss"
import "@fortawesome/fontawesome-free/scss/regular.scss"
import "@fortawesome/fontawesome-free/scss/brands.scss"
import 'sweetalert2/dist/sweetalert2.min.css'

import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/scss/main.scss'
import Vue2TouchEvents from 'vue2-touch-events'
 

Vue.use(VueSweetalert2)
Vue.use(PortalVue)
Vue.use(VCalendar)
Vue.use(Vue2TouchEvents)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
