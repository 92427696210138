import moment from 'moment'
import StatsAPI from '@/api/api'

const parseJwt = (token) => {
    var base64Url = token.split('.')[1]
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))

    return JSON.parse(jsonPayload)
}

export default {
  state: {
    token: null,
    username: null,
    dateDisable: null,
    dateInactivity: null,
    isAuthenticated: false 
  },
  mutations: {
    loggedIn: (state, payload) => {
      state.username = payload.username
      state.dateDisable = moment(payload.date_disable)
      state.dateInactivity = moment(payload.date_inactivity)
      state.isAuthenticated = true
    },
    setToken: (state, token) => {
      state.token = token
    }
  },
  getters: {
    getUsername (state) {
      return state.username
    },
    isDisabled (state) {
      if (state.dateDisable === null) {
        return false
      }
      return moment() > state.dateDisable
    },
    isInactive (state) {
      if (state.dateInactivity === null) {
        return false
      }
      return moment() > state.dateInactivity
    },
    isLoggedIn (state) {
      return state.isAuthenticated
    }
  },
  actions: {
    ensureLoggedIn (context, token) {
      StatsAPI.me().then((response) => {
        console.log("already logged in")
        let tokenData = parseJwt(token)
        if (response.data.user.includes("_" + tokenData.id)) {
          return context.commit('loggedIn', response.data)
        } else {
          console.log("logged in with wrong account... must relogin")
          return context.dispatch('doLogin', token)
        }
      }).catch(() => {
        console.log("Not logged in. Trying to login...")
        return context.dispatch('doLogin', token)
      })
    },
    doLogin (context, token) {
      context.commit('setToken', token)
      return StatsAPI.login(token).then((response) => {
        console.log("Logged in")
        console.log(response)
        return context.commit('loggedIn', response.data)
      }).catch(() => {
        console.log("Authentication error")
      })
    }
  }

}
