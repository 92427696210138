import axios from 'axios'

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
axios.defaults.xsrfCookieName = "csrftoken"
axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_AXIOS_BASEURL

export const StatsSharedAPI = {
  axios,
  getCookie (name) {
    let match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) return match[2];
  },
  getConfig (data) {
    var config = { 'headers': {} }
    if (data) {
      config['headers']['Content-Type'] = 'multipart/form-data'
    }
    return config
  },
  getPayload (data) {
    var payload = new FormData()
    for (var key in data) {
      if (Array.isArray(data[key])) {
        data[key].forEach(item => {
          payload.append(key, item)
        })
      } else {
        payload.set(key, data[key])
      }
    }
    payload.set('csrfmiddlewaretoken', this.getCookie('csrftoken'))
    return payload
  },
  login (token) {
    let payload = this.getPayload({token})
    let config = this.getConfig(payload)
    return axios.post('/login/', payload, config)
  },
  totals (payload) {
    return axios.get('/shared/totals/', { params: payload })
  },
  countries (payload) {
    return axios.get('/shared/countries/', { params: payload })
  },
  visits_evolution_chart (payload) {
    return axios.get('/shared/visits_evolution_chart/', { params: payload })
  },
  analytics_by_tour (payload) {
    return axios.get('/shared/analytics_by_tour/', { params: payload })
  },
  tour_medias (payload) {
    return axios.get('/shared/' + payload.code + '/tour_medias/', { params: payload })
  },
  tour_hotspots (payload) {
    return axios.get('/shared/' + payload.code + '/tour_hotspots/', { params: payload })
  },
  media_heatmap (payload, token) {
    console.log("Media heatmap request")
    let config = { cancelToken: token }
    console.log("Config:", config)
    return axios.get('/shared/' + payload.code + '/media_heatmap/', { params: payload }, config)
  },
  daily_tour_chart (payload) {
    return axios.get('/shared/' + payload.code + '/daily_tour_chart/', { params: payload })    
  }
}

export default StatsSharedAPI
