import StatsAPI from '@/api/api'


export default {
  state: {
    totals: {
      visits: 0,
      users: 0,
      time: 0
    },
    countries: [],
    evolution: {
      labels: [],
      values: []
    }
  },
  mutations: {
    setGlobalStats: (state, payload) => {
      state.totals = payload
    },
    setGlobalCountriesStats: (state, payload) => {
      state.countries = payload
    },
    setGlobalEvolutionStats: (state, payload) => {
      state.evolution = payload
    }
  },
  getters: {
    getGlobalStats (state) {
      return state.totals
    },
    getGlobalCountriesStats (state) {
      return state.countries
    },
    getGlobalEvolutionStats (state) {
      return state.evolution
    }
  },
  actions: {
    updateGlobalStats (context, payload) {
      return StatsAPI.totals(payload).then((response) => {
        context.commit('setGlobalStats', response.data)
      })
    },
    updateGlobalCountriesStats (context, payload) {
      return StatsAPI.countries(payload).then((response) => {
        context.commit('setGlobalCountriesStats', response.data)
      })
    },
    updateGlobalEvolutionStats (context, payload) {
      return StatsAPI.visits_evolution_chart(payload).then((response) => {
        context.commit('setGlobalEvolutionStats', response.data)
      })
    }
  }

}
