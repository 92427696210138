import Vue from 'vue'
import Vuex from 'vuex'

import dateFilter from './datefilter.module'
import auth from './auth.module'
import globalStats from './globalstats.module'
import tours from './tours.module'
import sharedtour from './sharedtour.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    dateFilter,
    globalStats,
    tours,
    sharedtour
  }
})
