<template>
  <div id="app">
    <main>
      <div class="wrapper">
        <div class="bar-navigation sps">
          <DateFilters v-if="showFilters"></DateFilters>
        </div>
        <div class="jander">
          <transition name="fade">
            <router-view></router-view>
          </transition>
        </div>
        <portal-target name="footer" slim></portal-target>
      </div>
    </main>
    <portal-target name="modals"></portal-target>

  </div>
</template>

<script>
  import DateFilters from '@/components/DateFilters.vue'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    components: {
      DateFilters
    },
    methods: {
      ...mapActions(['ensureLoggedIn']),

      checkLogin () {
        if ('token' in this.$route.query) {
          let token = this.$route.query.token
          this.token = token
          this.ensureLoggedIn(token).catch(() => {
            this.$router.push({'name': 'error', 'query': {'message': 'No credentials were provided.'}})
            this.loggedIn = false
          }).then(() => {
            this.loggedIn = true
            this.showFilters = false
            this.$nextTick(() => {
              this.showFilters = true
            })
          })
        } else {
          this.loggedIn = false
          setTimeout(() => { this.checkLogin() }, 1500)

          //this.ensureLoggedIn('notoken').catch(() => {
          //  this.$router.push({'name': 'error', 'query': {'message': 'No credentials were provided.'}})
            // console.log("No authentication provided")
          //})
        }
      }
    },
    computed: {
      ...mapGetters(['isLoggedIn', 'getTours']),
      isTourDetail() {
        return this.$route.name === "tourDetail" 
      },
      isSharedTourDetail() {
        return this.$route.name === "sharedTourDetail" 
      },
      tourSharedLink() {
        if (this.$route.name === "tourDetail" && this.currentTour) {
          return document.location.protocol + '//' + document.location.host + '/#/sharedtour/' + this.currentTour.uuid
        } else {
          return ""
        }
      },
      currentTourCode () {
        return this.$route.params.code
      },
      currentTour () {
        return this.getTours[this.currentTourIndex]
      },
      currentTourIndex () {
        return this.getTours.findIndex( (item) => item.code === this.currentTourCode )
      }
    },
    watch: {
      $route (newRoute) {
        if (!this.loggedIn) {
          this.checkLogin()
        }
        console.log(newRoute)
      }
    },
    data () {
      return {
        showShare: false,
        loggedIn: false,
        showFilters: true,
        token: null
      }
    },
    mounted () {
      this.checkLogin()
    }
  };
</script>

<style lang="scss">

.jander {

  height: calc(100vh - 60px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.jander::-webkit-scrollbar, *::-webkit-scrollbar {
  width: 8px;
}
 
.jander::-webkit-scrollbar-track, *::-webkit-scrollbar-track {
  background: transparent;
}
 
.jander::-webkit-scrollbar-thumb, *::-webkit-scrollbar-thumb {
  background: #008dca; 
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
  // transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.fade-enter {
  opacity: 0;
  overflow: hidden;
}

.fade-leave-active {
  opacity: 0;
  overflow: hidden;
}
.error-message {
  .material-icons {
    vertical-align: bottom;
  }
}

.btn-primary, .swal2-styled.swal2-confirm {
  background-color: #2c9dd3 !important;
  border-color: #2c9dd3 !important;
}
#button-copy-link.btn {
  margin-left: 4px;
}
.modal-content {
  padding: 1.1em;
}

</style>
