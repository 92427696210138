import StatsSharedAPI from '@/api/sharedapi'


export default {
  state: {
    tours: [],
    tours_count: null,
    tour_medias: [],
    tour_hotspots: [],
    tour_chart: {},
    sortConfig: {
      sortKey: 'percentage',
      sortOrder: 'desc'
    }
  },
  mutations: {
    setShTours: (state, payload) => {
      state.tours = payload
      state.tours_count = state.tours.length
    },
    sortShTours: (state, { sortKey, sortOrder }) => {
      let compareValues = (key, order = 'asc') => {
        let k = key
        if (key === 'percentage') {
          k = 'visits'
        }
        return function innerSort(a, b) {
          let varA
          let varB
          if (k === 'country') {
            varA = a['top_country']['views']
            varB = b['top_country']['views']
          } else if (k === 'percentage') {
            varA = parseFloat(a['percentage'].substring(0, a['percentage'].length - 1))
            varB = parseFloat(b['percentage'].substring(0, b['percentage'].length - 1))
          } else {
            varA = (typeof a[k] === 'string')
              ? a[k].toUpperCase() : a[k];
            varB = (typeof b[k] === 'string')
              ? b[k].toUpperCase() : b[k];
          } 

          let comparison = 0;
          if (varA > varB) {
            comparison = 1;
          } else if (varA < varB) {
            comparison = -1;
          }
          return (
            (order === 'desc') ? (comparison * -1) : comparison
          );
        };
      }
      const tours = state.tours
      tours.sort(compareValues(sortKey, sortOrder))
      state.tours = tours
    },
    setShTourMedias: (state, payload) => {
      let compareValues = (order = 'desc') => {
        return function innerSort(a, b) {
          let varA = a['visits']
          let varB = b['visits']

          let comparison = 0;
          if (varA > varB) {
            comparison = 1;
          } else if (varA < varB) {
            comparison = -1;
          }
          return (
            (order === 'desc') ? (comparison * -1) : comparison
          );
        };
      }
      const medias = payload
      medias.sort(compareValues('desc'))
      state.tour_medias = medias
    },
    setShTourHotspots: (state, payload) => {
      let compareValues = (order = 'desc') => {
        return function innerSort(a, b) {
          let varA = a['clicks']
          let varB = b['clicks']

          let comparison = 0;
          if (varA > varB) {
            comparison = 1;
          } else if (varA < varB) {
            comparison = -1;
          }
          return (
            (order === 'desc') ? (comparison * -1) : comparison
          );
        };
      }
      const hotspots = payload
      hotspots.sort(compareValues('desc'))
      state.tour_hotspots = payload
    },
    setShTourChart: (state, payload) => {
      state.tour_chart = payload
    },
    setShSortConfig: (state, payload) => {
      state.sortConfig = payload
    }
  },
  getters: {
    getShTour (state) {
      return state.tours[0]
    },
    getShTours (state) {
      return state.tours
    },
    getShTourMedias (state) {
      return state.tour_medias
    },
    getShTourHotspots (state) {
      return state.tour_hotspots
    },
    getShToursCount (state) {
      return state.tours_count
    },
    getShTourChart (state) {
      return state.tour_chart
    },
    getShSortConfig (state) {
      return state.sortConfig
    }
  },
  actions: {
    updateShTours (context, payload) {
      return StatsSharedAPI.analytics_by_tour(payload).then((response) => {
        context.commit('setShTours', response.data)
      })
    },
    updateShTourMedias (context, payload) {
      return StatsSharedAPI.tour_medias(payload).then((response) => {
        context.commit('setShTourMedias', response.data)
      })
    },
    updateShTourHotspots (context, payload) {
      return StatsSharedAPI.tour_hotspots(payload).then((response) => {
        context.commit('setShTourHotspots', response.data)
      })
    },
    updateShTourDailyChart(context, payload) {
      return StatsSharedAPI.daily_tour_chart(payload).then((response) => {
        context.commit('setShTourChart', response.data)
      })
    }
  }
}
